import React from 'react'
import HomeRequest from '../components/HomeRequest/HomeRequest'

import Layout from '../components/Layout'
import Reviews from '../components/Reviews/Reviews'
import ReviewsHero from '../components/ReviewsHero/ReviewsHero'
import ReviewsPlatforms from '../components/ReviewsPlatforms/ReviewsPlatforms'

const Portfolio = () => {
	return (
		<Layout
			title="Отзывы | AppCraft"
			ogSiteName="AppCraft"
			content="✅ Отзывы про AppCraft."
			keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
			ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
			ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
		>
			<ReviewsHero />
			<Reviews />
			<ReviewsPlatforms />
			<HomeRequest />
		</Layout>
	)
}

export default Portfolio
