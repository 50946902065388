// extracted by mini-css-extract-plugin
export var footer = "reviews-module--footer--e0dbd";
export var link__logo = "reviews-module--link__logo--c759f";
export var linktext = "reviews-module--linktext--f8f26";
export var original = "reviews-module--original--3889b";
export var original__link = "reviews-module--original__link--163c2";
export var original__link__wrap = "reviews-module--original__link__wrap--26633";
export var original__link_primary = "reviews-module--original__link_primary--58aba";
export var owner = "reviews-module--owner--171b0";
export var project = "reviews-module--project--8e3c5";
export var project__logo = "reviews-module--project__logo--a4c31";
export var project__title = "reviews-module--project__title--ec873";
export var review = "reviews-module--review--1d724";
export var reviews = "reviews-module--reviews--601fc";
export var section = "reviews-module--section--6451a";
export var store = "reviews-module--store--509ba";
export var store__link = "reviews-module--store__link--eca2c";
export var store__link_apple = "reviews-module--store__link_apple--3d1e8";
export var store__link_google = "reviews-module--store__link_google--7a426";
export var text = "reviews-module--text--92cbd";
export var text__wrap = "reviews-module--text__wrap--db53c";