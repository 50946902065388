import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import * as classes from './reviews.module.css'
import appstoreImg from '../../images/app-store-rus-main.svg'
import googleplayImg from '../../images/google-play-rus-main.svg'
import Icon from '../Icon'
import Boop from '../Boop'
import Img from 'gatsby-image'

const Review = ({ appStore, googlePlay, logo, original, owner, portfolio, project, review, linkLogo }) => {
	return (
		<div className={classes.review}>
			<div>
				<h4 className={classes.owner}>{owner}</h4>
				<div className={classes.project}>
					{!!logo && <Img className={classes.project__logo} fluid={logo.node.childImageSharp.fluid} />}
					<h5 className={classes.project__title}>{project}</h5>
				</div>
				<div className={classes.text__wrap}>
					<p className={classes.text}>{review}</p>
				</div>
			</div>
			<div className={classes.footer}>
				<div className={classes.store}>
					{!!appStore && (
						<Boop path={appStore} styles={`${classes.store__link} ${classes.store__link_apple}`} scale={1.03}>
							<img src={appstoreImg} alt="" />
						</Boop>
					)}
					{!!googlePlay && (
						<Boop
							path={googlePlay}
							styles={`${classes.store__link} ${classes.store__link_google}`}
							scale={1.03}
						>
							<img src={googleplayImg} alt="" />
						</Boop>
					)}
				</div>
				{(!!portfolio || !!original) && (
					<div className={classes.original}>
						{!!portfolio && (
							<Link to={portfolio.link} className={classes.original__link}>
								{!!linkLogo && (
									<img className={classes.link__logo} src={linkLogo && linkLogo.node.publicURL} alt="" />
								)}
								{portfolio.name}
							</Link>
						)}
						{!!original && (
							<div className={classes.original__link__wrap}>
								<a href={original} target="_blank" className={classes.original__link_primary}>
									<span className={classes.linktext}>Оригинал отзыва</span>
									<Icon name="arrow-right" width="14" height="14" className="card-ctaicon fill-current" />
								</a>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

const Reviews = () => {
	const { allReviewsJson: reviews, icons } = useStaticQuery(graphql`
		query {
			allReviewsJson {
				edges {
					node {
						appStore
						googlePlay
						logo
						original
						owner
						portfolio {
							link
							name
						}
						project
						review
					}
				}
			}
			icons: allFile(filter: { relativePath: { regex: "/-icon-review/" } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 70, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`)

	return (
		<section className={classes.section}>
			<div className={`${classes.reviews} container`}>
				{reviews.edges.map(({ node: review }) => {
					let logo = icons.edges.find(({ node }) => node.relativePath === review.logo)

					return (
						<Review
							key={review.review}
							appStore={review.appStore}
							googlePlay={review.googlePlay}
							logo={logo}
							original={review.original}
							owner={review.owner}
							portfolio={review.portfolio}
							project={review.project}
							review={review.review}
						/>
					)
				})}
			</div>
		</section>
	)
}

export default Reviews
