import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as classes from './reviews-hero.module.css'

import ReviewsTags from '../ReviewsTags/ReviewsTags'

const ReviewsHero = () => {
	const { allReviewstagsJson: tags } = useStaticQuery(graphql`
		query {
			allReviewstagsJson {
				edges {
					node {
						name
					}
				}
			}
		}
	`)

	return (
		<section className={classes.section}>
			<div className={classes.ellipse} />
			<div className="container">
				<h1 className={classes.title}>
					Отзывы клиентов
					<br /> Что говорят наши заказчики о команде Appcraft?
				</h1>
				<ReviewsTags reviews={tags.edges} />
			</div>
		</section>
	)
}

export default ReviewsHero
