import React from 'react'

import * as classes from './reviews-platforms.module.css'

const platfoms = [
	{ name: 'Clutch', link: 'https://clutch.co/profile/appcraft#reviews' },
	{ name: 'Ruward', link: 'https://ruward.ru/catalog/appcraft-pro/#revisions' },
	{ name: 'Wadline', link: 'https://wadline.ru/appcraft#company-tab-reviews' },
]

const Platform = ({ name, link }) => {
	return (
		<div className={classes.platform}>
			<a
				className={`${classes.button} btn btn--primary btn--outline w-full sm:w-auto`}
				href={link}
				target="_blank"
				rel="nofollow"
			>
				{name}
			</a>
		</div>
	)
}

const ReviewsPlatforms = () => {
	return (
		<section className={`${classes.section} container`}>
			<h2 className={classes.title}>
				Заказчики оставляют отзывы о нас на страницах крупных рейтингов и отраслевых платформ
			</h2>

			<div className={classes.platforms}>
				{platfoms.map((platform) => (
					<Platform key={platform.name} name={platform.name} link={platform.link} />
				))}
			</div>
		</section>
	)
}

export default ReviewsPlatforms
