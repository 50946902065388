import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import * as classes from './reviews-tags.module.css'
import SwiperCore, { EffectCreative, Autoplay } from 'swiper'

SwiperCore.use([EffectCreative, Autoplay])

export default function ReviewsTags({ reviews }) {
	return (
		<Swiper
			effect={'creative'}
			loop={true}
			speed={2000}
			autoplay={{ delay: 1500 }}
			simulateTouch={false}
			creativeEffect={{
				prev: {
					translate: [0, 0, -800],
					rotate: [180, 0, 0],
				},
				next: {
					translate: [0, 0, -800],
					rotate: [-180, 0, 0],
				},
			}}
			className={classes.swiper}
		>
			{reviews.map(({ node: review }) => (
				<SwiperSlide key={review.name} className={classes.slide}>
					{({ isPrev }) => (
						<div className={`${classes.slide__wrap} ${!isPrev ? classes.slide__wrap_active : ''}`}>
							{review.name}
						</div>
					)}
				</SwiperSlide>
			))}
		</Swiper>
	)
}
